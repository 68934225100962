import { navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/templates/layout"
import { AccountContext } from "../context/account"

const IndexPage = () => {
  const { isLoggedIn } = useContext(AccountContext)
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login")
    } else {
      navigate("/home")
    }
  }, [isLoggedIn])

  return (
    <Layout>
      <SEO title="Home" />
      <div>hi index</div>
    </Layout>
  )
}

export default IndexPage
